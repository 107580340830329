<template>
  <div>
    <div class="header-content">
      <div style="flex:1">
        <span style="font-size:18px;color:#344563;font-weight:bold;margin-right:5px">小程序</span>
        <a @click="addStaffShow = true">+设置任务量</a>
        <a @click="openBatch" style="margin-left:10px">批量设置任务量</a>
      </div>
      <div>
        <a-form :model="searchForm" layout="inline">
          <a-form-item label="">
            <a-month-picker style="width:100px" v-model:value="searchForm.date" @change="montChange" show-time
              placeholder="月份选择" allowClear />
          </a-form-item>
          <a-form-item label="">
            <a-select v-model:value="searchForm.orgId" style="width: 140px" placeholder="选择部门" allowClear>
              <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-input v-model:value="searchForm.empName" style="width:100px" placeholder="业务员" allowClear />
          </a-form-item>
          <a-form-item label="" style="margin-right:0">
            <div class="operate-btn">
              <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
              <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table-content">
      <a-table ref="tableRef" size="small" :columns="columns" :row-key="record => record.orderId" :data-source="tableList"
        :rowClassName="(record, index) => (record.empName === '' ? 'table-color2' : null)" bordered :pagination="false"
        :scroll="{ y: 270 }" :loading="loading" @change="pageChange">
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="addStaffShow" title="添加任务" @ok="confirmAdd" @cancel="closeAdd" :confirmLoading="loading">
    <div>
      <a-form ref="addFormRef" :model="addForm" :rules="rules" :label-col="{ span: 100 }" :wrapper-col="{ span: '100%' }"
        layout="vertical">
        <a-form-item label="选择员工" name="empId">
          <a-select v-if="addStaffShow" :filter-option="false" show-search placeholder="选择员工" @search="employerValue">
            <template v-if="flagLoading" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name"
              @click="employeeChoose(item)">{{ item.name }}-{{ item.orgName }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="选择月份" name="dateTime">
          <a-month-picker style="width:100%" v-model:value="addForm.dateTime" @change="dateTimeChange" show-time
            :disabled-date="disabledDate" placeholder="请选择" />
        </a-form-item>
        <a-form-item label="任务量" name="taskNum">
          <a-input v-model:value="addForm.taskNum" tyle="number" :min="1" placeholder="输入任务量（台）" />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
  <!-- 批量设置任务 -->
  <BatchSetPlant ref="batchSetPlantRef" :type="takeType"/>
</template>

<script>
import moment from 'moment'
import { reactive, toRefs, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { employeeList } from '@/api/crmManagement/setRules'
import { appletPage, addData } from '@/api/statistics/appletTask'
import BatchSetPlant from '../taskStatistics/batchSetPlant.vue'

export default {
  components: {
    BatchSetPlant
  },
  setup () {
    const batchSetPlantRef = ref(null)
    const addFormRef = ref(null)
    const state = reactive({
      loading: false,
      addStaffShow: false,
      flagLoading: false,
      searchForm: {
        orgId: null,
        empName: '',
        date: ''
      },
      addForm: {
        empId: '',
        empName: '',
        dateTime: '',
        taskNum: ''
      },
      takeType: 1,
      mirrorAddForm: {},
      mirrorSearchFrom: {},
      tableList: [],
      employeeOptions: [],
      rules: {
        empId: { required: true, message: '请选择员工', trigger: 'blur', type: 'string' },
        dateTime: { required: true, message: '请选择月份', trigger: 'blur', type: 'string' },
        taskNum: { required: true, message: '请输入任务量', trigger: 'blur', type: 'string' }
      },
      columns: [
        {
          title: '部门',
          dataIndex: 'orgName',
          align: 'center',
          width: 120,
          fixed: 'left'
        },
        {
          title: '员工',
          dataIndex: 'empName',
          align: 'center',
          width: 100,
          fixed: 'left'
        },
        {
          title: '任务量',
          dataIndex: 'taskNum',
          align: 'center'
        },
        {
          title: '本月累计台量',
          dataIndex: 'totalCarNum',
          align: 'center'
        },
        {
          title: '完成率',
          dataIndex: 'completionRate',
          align: 'center'
        }
      ],
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['5', '10', '15', '20'],
        pageSize: 7
      }
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      state.mirrorAddForm = JSON.stringify(state.addForm)
      loadData()
    })
    const loadData = () => {
      state.loading = true
      appletPage({
        ...state.searchForm
      }).then(res => {
        if (res.code === 10000) {
          state.tableList = res.data
          state.tableList.forEach(item => {
            item.completionRate = item.completionRate + '%'
          })
        }
      }).finally(() => { state.loading = false })
    }
    const openBatch = () => {
      state.takeType = 1
      batchSetPlantRef.value.visible = true
      batchSetPlantRef.value.takeType = 1
      if(batchSetPlantRef.value.listData.length <= 0) batchSetPlantRef.value.loadData()
    }
    // 禁用时间
    const disabledDate = current => {
      let MM = moment().format('YYYY-MM')
      const DD = moment().format('D')
      if (DD > 5 && DD < 20) {
        MM = moment().add(0, 'months').format('YYYY-MM')
      }
      return current && current < moment(MM)
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.tableList = []
      searchData()
    }
    const pageChange = page => {
      state.pagination = page
      // loadData()
    }
    const montChange = (e, v) => {
      state.searchForm.date = v
    }
    const dateTimeChange = (e, v) => {
      state.addForm.dateTime = v + '-01 00:00:00'
    }
    const employerValue = e => {
      if (e === '' || e === null) clearTimeout(state.timer)
      state.flagLoading = true
      if (state.timer !== null) {
        clearTimeout(state.timer)
      }
      state.timer = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        }).then(res => {
          if (res.code === 10000) {
            state.employeeOptions = res.data
            state.flagLoading = false
          }
        }).catch(err => { console.log(err) })
      }, 1000)
    }
    const employeeChoose = (item) => {
      state.addForm.empId = item.id
      state.addForm.empName = item.name
      state.employeeOptions = []
    }
    const confirmAdd = () => {
      addFormRef.value.validate().then(() => {
        state.loading = true
        addData(state.addForm).then(res => {
          if (res.code === 10000) {
            message.success('添加成功')
            loadData()
            state.addForm = JSON.parse(state.mirrorAddForm)
            state.addStaffShow = false
          }
        }).finally(() => { state.loading = false })
      })
    }
    const closeAdd = () => {
      state.addForm = JSON.parse(state.mirrorAddForm)
      state.addStaffShow = false
    }

    return {
      ...toRefs(state),
      addFormRef,
      batchSetPlantRef,
      loadData,
      openBatch,
      searchData,
      resetData,
      pageChange,
      confirmAdd,
      closeAdd,
      montChange,
      disabledDate,
      dateTimeChange,
      employeeChoose,
      employerValue
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td) {
  padding: 4px !important;
  color: #344563 !important;
}

:deep(.ant-table-align-center) {
  padding: 4px !important;
}

:deep(.ant-table-pagination.ant-pagination) {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

:deep(.ant-form-item) {
  margin-right: 4px !important;
}

.header-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.operate-btn {
  :deep(.ant-btn) {
    border-radius: 0;
  }

  .header-search-btn {
    color: #fff;
    margin-right: 5px;
    border-color: #344563;
    background-color: #344563;
  }

  .header-search-btn:hover {
    color: #344563;
    background-color: #fff;
    border-color: #344563;
  }

  .header-reset-btn:focus {
    color: #344563;
    border-color: #ccc;
    background-color: #F0F2F5;
  }

  // .header-search-btn:focus {
  //   color: #000;
  //   border-color: #344563;
  // }
}
</style>
