<template>
  <div>
    <div class="header-content">
      <div style="flex:1">
        <span style="font-size:18px;color:#344563;font-weight:bold;margin-right:25px">每日台量</span>
      </div>
      <div style="display: flex;justify-content:flex-end">
        <a-form :model="searchForm" layout="inline">
          <a-form-item label="">
            <a-select v-model:value="searchForm.taskType" style="width: 130px" placeholder="选择员工类型" allowClear>
              <a-select-option :value="0">录单员</a-select-option>
              <a-select-option :value="2">分单员</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-month-picker style="width:100px" v-model:value="searchForm.date" @change="montChange" show-time
              placeholder="月份选择" allowClear/>
          </a-form-item>
          <a-form-item label="">
            <a-select v-model:value="searchForm.orgId" style="width: 150px" placeholder="选择部门" allowClear>
              <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-input v-model:value="searchForm.empName" placeholder="业务员" allowClear/>
          </a-form-item>
          <a-form-item label="" style="margin-right:0">
            <div class="operate-btn">
              <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
              <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table-content">
      <a-table ref="tableRef" size="small" :columns="columns" :row-key="record => record.orderId" :data-source="tableList"
        bordered :scroll="scrollData" :pagination="false" :loading="loading" @change="pageChange">
      </a-table>
      <!-- <table border="1" width="100%" align="center">
        <tr v-for="(item,index) in tableList" :key="index">
          <td>{{ item.empName }}</td>
          <td  v-for="(item,index) in item.dailyTaskStat" :key="index">{{ item.carNum }}台</td>
        </tr>
        <tr v-for="(item,index) in tableList" :key="index">
          <td  v-for="(item,index) in item.dailyTaskStat" :key="index">{{ item.orderNum }}订</td>
        </tr>
        <tr v-for="(item,index) in tableList" :key="index">
          <td  v-for="(item,index) in item.dailyTaskStat" :key="index">{{ item.lessFourOrderNum }} 4小订单量</td>
        </tr>
        <tr v-for="(item,index) in tableList" :key="index">
          <td  v-for="(item,index) in item.dailyTaskStat" :key="index">{{ item.moreFourOrderNum }}4大订单量</td>
        </tr>
      </table> -->
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { dailyTaskPage } from '@/api/statistics/dailyTask.js'

export default {
  setup () {
    const state = reactive({
      loading: false,
      scrollData: { x: 0, y: 270 },
      searchForm: {
        date: '',
        taskType: null,
        department: null,
        empName: ''
      },
      childrenArr: [],
      mirrorSearchForm: {},
      tableList: [],
      columnsArray: [
      ],
      columns: [],
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['5', '10', '15', '20'],
        pageSize: 8
      }
    })

    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      loadData()
    })
    const loadData = () => {
      state.loading = true
      dailyTaskPage({
        ...state.searchForm
      }).then(res => {
        if (res.code === 10000) {
          state.tableList = res.data
          if(state.tableList.length <= 0) return
          if (res.data.length > 0 && res.data[0].dailyTaskStat.length > 0) state.scrollData = { x: res.data[0].dailyTaskStat.length * 83, y: 270 }
          else state.scrollData = {}
          const arr = []
          state.tableList.map((v, index) => {
            const array = ['台量', '订单量', '订单量≥4', '订单量＜4']
            array.forEach(function (item, i) {
              const obj = {
                orgName: v.orgName,
                empName: v.empName,
                key: i + 1,
                type: item
              }
              const obj2 = {}
              v.dailyTaskStat.map((w) => {
                if (i === 0) {
                  obj2[w.reportDateTime] = w.carNum
                }
                if (i === 1) {
                  obj2[w.reportDateTime] = w.orderNum
                }
                if (i === 2) {
                  obj2[w.reportDateTime] = w.moreFourOrderNum
                }
                if (i === 3) {
                  obj2[w.reportDateTime] = w.lessFourOrderNum
                }
              })
              arr.push({ ...obj, ...obj2 })
            })
          })
          // arr.map((v, index) => {
          //   console.log(v)
          // })
          const dt = state.tableList[0].dailyTaskStat
          state.tableList = arr
          state.columns = [
            {
              title: '部门',
              dataIndex: 'orgName',
              key: 'orgName',
              align: 'center',
              fixed: 'left',
              // 处理列表的合并
              customRender: ({ text, index }) => {
                const obj = {
                  children: text,
                  props: {}
                }
                if ((index) % 4 === 0) {
                  obj.props.rowSpan = 4
                } else {
                  obj.props.rowSpan = 0
                }
                return obj
              }
            },
            {
              title: '员工',
              dataIndex: 'empName',
              key: 'empName',
              align: 'center',
              fixed: 'left',
              // 处理列表的合并
              customRender: ({ text, index }) => {
                const obj = {
                  children: text,
                  props: {}
                }
                if ((index) % 4 === 0) {
                  obj.props.rowSpan = 4
                } else {
                  obj.props.rowSpan = 0
                }
                return obj
              }
            },
            {
              title: '类别',
              dataIndex: 'type',
              width: 100,
              key: 'type',
              align: 'center',
              fixed: 'left'
            },
            {
              title: '日期',
              // 动态表头
              children: dt.map((v) => ({
                title: v.reportDateTime,
                dataIndex: v.reportDateTime,
                align: 'center'
              }))
            }
          ]
          state.pagination.total = res.data.total
        }
      }).finally(() => { state.loading = false })
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.tableList = []
      searchData()
    }
    const pageChange = page => {
      state.pagination = page
      // loadData()
    }
    const montChange = (e, v) => {
      state.searchForm.date = v
    }
    return {
      ...toRefs(state),
      searchData,
      resetData,
      montChange,
      pageChange
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td) {
  padding: 4px !important;
  color: #344563 !important;
}

:deep(.ant-table-align-center) {
  padding: 4px !important;
}

:deep(.ant-form-item) {
  margin-right: 4px !important;
}

:deep(.ant-table-cell-fix-left) {
  z-index: 0 !important
}

:deep(.ant-table-pagination.ant-pagination) {
  margin: 0 !important;
}

.header-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.operate-btn {
  :deep(.ant-btn) {
    border-radius: 0;
  }

  .header-search-btn {
    color: #fff;
    margin-right: 10px;
    border-color: #344563;
    background-color: #344563;
  }

  .header-search-btn:hover {
    color: #344563;
    background-color: #fff;
    border-color: #344563;
  }

  .header-reset-btn:focus {
    color: #344563;
    border-color: #ccc;
    background-color: #F0F2F5;
  }

  // .header-search-btn:focus {
  //   color: #000;
  //   border-color: #344563;
  // }
}
</style>
