import { postBodyRequest, getRequest } from '@/utils/axios'

export function getEmpTaskList (params) {
  return postBodyRequest('/anal/emp/task/getEmpTaskList', params)
}
export function addBatchEmpTask (params) {
  return postBodyRequest('/anal/emp/task/addBatchEmpTask', params)
}
export function addBatchEmpTaskMin (params) {
  return postBodyRequest('/anal/emp/task/addBatchEmpTaskMin', params)
}
export function deleteEmpTask (params) {
  return getRequest('/anal/emp/task/deleteEmpTask', params)
}