import { postBodyRequest, getRequest } from '@/utils/axios'

export function addData (params) {
  return postBodyRequest('/anal/emp/task/addEmployeeTask', params)
}

export function yesterDayPage (params) {
  return postBodyRequest('/anal/emp/task/yesterdayReport', params)
}
export function updateData (params) {
  return getRequest('/anal/emp/task/calculateTodayReport', params)
}