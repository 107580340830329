<template>
  <div class="subject">
    <div class="height" style="background:#fff;padding:10px;">
      <YesterdayTask :height="yesterdayHeight" />
    </div>
    <div class="height" style="display: flex;margin-top:5px;">
      <div style="width:59.5%;margin-right:0.5%;background:#fff;padding:10px;padding-bottom:0">
        <DailyTask />
      </div>
      <div style="width:40%;background:#fff;padding:10px">
        <AppletTask />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import YesterdayTask from '../module/yesterdayTask.vue'
import DailyTask from '../module/dailyTask.vue'
import AppletTask from '../module/appletTask.vue'

export default {
  components: {
    YesterdayTask,
    DailyTask,
    AppletTask,
  },
  setup () {
    const state = reactive({
      subjectHeight: 0,
      yesterdayHeight: 0
    })
    const loadDOM = () => {
      state.subjectHeight = document.body.clientHeight - 130
      const DOM = document.querySelectorAll('.height')
      state.yesterdayHeight = ((state.subjectHeight / 2)) - 20
      // DOM[0].style.height = ((state.subjectHeight / 2)) - 20 + 'px'
      // DOM[1].style.height = ((state.subjectHeight / 2)) + 'px'
      DOM.forEach((item, index) => {
        if(index === 0) item.style.height = ((state.subjectHeight / 2)) - 20 + 'px'
        item.style.height = ((state.subjectHeight / 2)) + 'px'
      })
    }
    onMounted(() => {
      loadDOM()
      window.onresize = function () {
        return (() => {
          loadDOM()
        })()
      }
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
