<template>
  <div ref="yesterdayRef" style="background:#fff">
    <div class="header-content">
      <div style="flex:1">
        <span style="font-size:18px;color:#344563;font-weight:bold;margin-right:5px">昨日战报</span>
        <a @click="addStaffShow = true" style="margin-right:15px">+设置任务量</a>
        <a-popconfirm title="确认更新今日数据?" ok-text="确认" cancel-text="取消" @confirm="uploadDayData">
          <a style="margin-right:15px">更新今日数据</a>
        </a-popconfirm>
        <a @click="openBatch">批量设置任务量</a>
      </div>
      <div>
        <a-form :model="searchForm" layout="inline">
          <!-- <a-form-item label="">
            <a-tooltip @click="handleFullscreen">
              <template #title>{{ isFullscreen ? $t('fullscreen.ok') : $t('fullscreen') }}</template>
              <FullscreenExitOutlined v-if="isFullscreen" />
              <FullscreenOutlined v-else />
            </a-tooltip>
          </a-form-item> -->
          <a-form-item label="">
            <a-select v-model:value="searchForm.taskType" style="width: 130px" placeholder="选择员工类型" allowClear>
              <a-select-option :value="0">录单员</a-select-option>
              <a-select-option :value="2">分单员</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-select v-model:value="searchForm.orgId" style="width: 150px" placeholder="选择部门" allowClear>
              <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-input v-model:value="searchForm.empName" placeholder="业务员" allowClear/>
          </a-form-item>
          <a-form-item label="" style="margin-right:0">
            <div class="operate-btn">
              <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
              <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table-content">
      <a-table ref="tableRef" :rowClassName="(record, index) => (record.empName === '' ? 'table-color2' : null)"
        size="small" :columns="columns" :row-key="record => record.orderId" :data-source="tableList" bordered
        :scroll="{ x: 2050, y: height - 80}" :pagination="false" :loading="loading" @change="pageChange">
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="addStaffShow" title="添加任务" @ok="confirmAdd" @cancel="closeAdd">
    <div>
      <a-form ref="addFormRef" :model="addForm" :rules="rules" :label-col="{ span: 100 }" :wrapper-col="{ span: '100%' }"
        layout="vertical">
        <a-form-item label="选择员工" name="empId">
          <a-select v-if="addStaffShow" :filter-option="false" show-search placeholder="选择员工" @search="employerValue">
            <template v-if="flagLoading" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name"
              @click="employeeChoose(item)">{{ item.name }}-{{ item.orgName }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="选择月份" name="dateTime">
          <a-month-picker style="width:100%" v-model:value="addForm.dateTime" @change="dateTimeChange" show-time
            :disabled-date="disabledDate" placeholder="请选择" />
        </a-form-item>
        <a-form-item label="任务量" name="taskNum">
          <a-input v-model:value="addForm.taskNum" placeholder="输入任务量（台）" />
        </a-form-item>
        <a-form-item label="目标利润" name="profit">
          <a-input v-model:value="addForm.profit" placeholder="输入总利润（元）" />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
  <!-- 批量设置任务 -->
  <BatchSetPlant ref="batchSetPlantRef" :type="takeType"/>
</template>

<script>
import moment from 'moment'
import { reactive, toRefs, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons-vue'
import { employeeList } from '@/api/crmManagement/setRules'
import BatchSetPlant from '../taskStatistics/batchSetPlant.vue'
import { fullScreen } from '@/utils/util'
import { addData, yesterDayPage, updateData } from '@/api/statistics/yesterdayTask'

export default {
  components: {
    FullscreenExitOutlined,
    FullscreenOutlined,
    BatchSetPlant
  },
  props: {
    height: Number
  },
  setup (props) {
    const batchSetPlantRef = ref(null)
    const addFormRef = ref(null)
    const yesterdayRef = ref(null)
    const state = reactive({
      loading: false,
      isFullscreen: false,
      addStaffShow: false,
      flagLoading: false,
      height: props.height,
      mirrorHeight: props.height,
      searchForm: {
        orgId: null,
        empName: ''
      },
      addForm: {
        empId: '',
        empName: '',
        dateTime: null,
        taskNum: '',
        profit: '',
        orgId: '',
        orgName: ''
      },
      takeType: 0,
      mirrorAddForm: {},
      mirrorSearchFrom: {},
      tableList: [],
      employeeOptions: [],
      rules: {
        empId: { required: true, message: '请选择员工', trigger: 'blur', type: 'string' },
        dateTime: { required: true, message: '请选择月份', trigger: 'blur', type: 'string' },
        taskNum: { required: true, message: '请输入任务量', trigger: 'blur', type: 'string' },
        profit: { required: true, message: '请输入总利润', trigger: 'blur', type: 'string' }
      },
      columns: [
        {
          title: '部门',
          dataIndex: 'orgName',
          width: '4%',
          align: 'center',
          fixed: 'left'
        },
        {
          title: '员工',
          dataIndex: 'empName',
          width: '4%',
          align: 'center',
          fixed: 'left'
        },
        {
          title: '任务量',
          width: '3%',
          dataIndex: 'taskNum',
          align: 'center'
        },
        {
          title: '当日台量',
          dataIndex: 'carNum',
          width: '3%',
          align: 'center'
        },
        {
          title: '本月累计台量',
          dataIndex: 'currentMonthCarNum',
          width: '4%',
          align: 'center'
        },
        {
          title: '完成率',
          width: '3%',
          dataIndex: 'completionRate',
          align: 'center'
        },
        {
          title: '时间进度差',
          width: '4%',
          dataIndex: 'timeTaskDiff',
          align: 'center'
        },
        {
          title: '上月同期台量',
          width: '4%',
          dataIndex: 'lastCurrentCarNum',
          align: 'center'
        },
        {
          title: '环比上月增长率',
          width: '5%',
          dataIndex: 'monthGrowthRate',
          align: 'center'
        },
        {
          title: '订单量≥4',
          width: '4%',
          dataIndex: 'moreFourOrderNum',
          align: 'center'
        },
        {
          title: '订单量＜4',
          width: '4%',
          dataIndex: 'lessFourOrderNum',
          align: 'center'
        },
        {
          title: '未交车',
          width: '3%',
          dataIndex: 'onWayNum',
          align: 'center'
        },
        {
          title: '本月已交车台量',
          width: '5%',
          dataIndex: 'currentMonthDeliveredNum',
          align: 'center'
        },
        {
          title: '本月目标利润',
          width: '5%',
          dataIndex: 'profit',
          align: 'center'
        },
        {
          title: '本月交车单台利润',
          width: '5%',
          dataIndex: 'currentPerCarProfit',
          align: 'center'
        },
        {
          title: '本月已交车利润',
          width: '5%',
          dataIndex: 'currentMonthDeliverProfit',
          align: 'center'
        },
        {
          title: '本月利润完成率',
          width: '5%',
          dataIndex: 'currentMonthProfitCompletionRate',
          align: 'center'
        },
        {
          title: '上月总毛利润',
          width: '4%',
          dataIndex: 'lastMonthDeliverProfit',
          align: 'center'
        },
        {
          title: '上月利润完成率',
          width: '5%',
          dataIndex: 'lastMonthProfitCompletionRate',
          align: 'center'
        },
        {
          title: '完成率排名',
          width: '4%',
          dataIndex: 'rankNum',
          align: 'center'
        }
      ],
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['5', '10', '15', '20'],
        pageSize: 10
      }
    })
    const openBatch = () => {
      state.takeType = 0
      batchSetPlantRef.value.visible = true
      if(batchSetPlantRef.value.listData.length <= 0) batchSetPlantRef.value.loadData()
    }
    // 全屏
    const handleFullscreen = () => {
      state.isFullscreen = !state.isFullscreen
      fullScreen(state.isFullscreen, yesterdayRef)
      if (state.isFullscreen === true){
        state.height = document.body.clientHeight
      }else state.height = state.mirrorHeight
      console.log(state.isFullscreen,state.height, state.mirrorHeight);
    }
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      state.mirrorAddForm = JSON.stringify(state.addForm)
      loadData()
    })
    const loadData = () => {
      state.loading = true
      yesterDayPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.tableList = res.data
          state.tableList.forEach(item => {
            item.completionRate = item.completionRate + '%'
            item.monthGrowthRate = item.monthGrowthRate + '%'
            item.currentMonthProfitCompletionRate = item.currentMonthProfitCompletionRate + '%'
            item.lastMonthProfitCompletionRate = item.lastMonthProfitCompletionRate + '%'
          })
          // state.pagination.total = res.data.total
        }
      }).finally(() => { state.loading = false })
    }
    // 禁用时间
    const disabledDate = current => {
      let MM = moment().format('YYYY-MM')
      const DD = moment().format('D')
      if (DD > 5 && DD < 20) {
        MM = moment().add(0, 'months').format('YYYY-MM')
      }
      return current && current < moment(MM)
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.tableList = []
      searchData()
    }
    const pageChange = page => {
      state.pagination = page
      // loadData()
    }
    const employerValue = e => {
      if (e === '' || e === null) clearTimeout(state.timer)
      state.flagLoading = true
      if (state.timer !== null) {
        clearTimeout(state.timer)
      }
      state.timer = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        }).then(res => {
          if (res.code === 10000) {
            state.employeeOptions = res.data
            state.flagLoading = false
          }
        }).catch(err => { console.log(err) })
      }, 1000)
    }
    const employeeChoose = (item) => {
      state.addForm.empId = item.id
      state.addForm.empName = item.name
      state.addForm.orgName = item.orgName
      // state.addForm.empName = item.name
      state.employeeOptions = []
    }
    const dateTimeChange = (e, v) => {
      state.addForm.dateTime = v + '-01 00:00:00'
    }
    const confirmAdd = () => {
      addFormRef.value.validate().then(() => {
        addData(state.addForm).then(res => {
          if (res.code === 10000) {
            message.success('添加成功')
            loadData()
            state.addForm = JSON.parse(state.mirrorAddForm)
            state.addStaffShow = false
          }
        })
      })
    }
    const closeAdd = () => {
      state.addForm = JSON.parse(state.mirrorAddForm)
      state.addStaffShow = false
    }
    const uploadDayData = () => {
      state.loading = true
      updateData().then(res => {
        if (res.code === 10000) {
          message.success('数据已更新')
        }
      }).finally(() => { state.loading = false })
    }

    return {
      ...toRefs(state),
      yesterdayRef,
      addFormRef,
      batchSetPlantRef,
      loadData,
      openBatch,
      searchData,
      handleFullscreen,
      resetData,
      pageChange,
      confirmAdd,
      closeAdd,
      uploadDayData,
      disabledDate,
      dateTimeChange,
      employeeChoose,
      employerValue
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td) {
  padding: 4px !important;
  color: #344563 !important;
}

:deep(.ant-table-align-center) {
  padding: 4px !important;
  color: #6B778C;
}

:deep(.ant-table-cell-fix-left) {
  z-index: 0 !important
}

:deep(.ant-table-pagination.ant-pagination) {
  margin: 0 !important;
}

:deep(.ant-form-item) {
  margin-right: 4px !important;
}

.header-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.operate-btn {
  :deep(.ant-btn) {
    border-radius: 0;
  }

  .header-search-btn {
    color: #fff;
    margin-right: 10px;
    border-color: #344563;
    background-color: #344563;
  }

  .header-search-btn:hover {
    color: #344563;
    background-color: #fff;
    border-color: #344563;
  }

  .header-reset-btn:focus {
    color: #344563;
    border-color: #ccc;
    background-color: #F0F2F5;
  }

  // .header-search-btn:focus {
  //   color: #000;
  //   border-color: #344563;
  // }
}

.background-table {
  background: #fbead4;
}
</style>
